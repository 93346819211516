import { useFetcher } from "@remix-run/react";

export const useLog = () => {
	const fetcher = useFetcher();
	const log = (message: string, severity: "error" | "info" = "info") => {
		fetcher.submit(
			{
				message,
				severity,
				// eslint-disable-next-line unicorn/error-message
				trace: new Error().stack ?? "",
			},
			{ method: "POST", action: "/log" },
		);
	};
	return log;
};
